<template>
  <v-container fluid>
    <FilterHeader
      v-if="selectedAppBarTab === 'filtered'"
      @newValues="updateValues($event)"
      :use-date="true"
      :use-staff="true"
    />
    <v-tabs-items
      :key="forceRenderKey"
      v-model="selectedAppBarTab"
      class="transparent"
    >
      <v-tab-item
        v-for="tab in tabs"
        :value="tab.key"
        :key="tab.key"
      >
        <TransientDash
          v-if="tab.key === 'not-clocked-out'"
          :key="'cost_of_active_labour - work'"
          title="Cost of Active Labour"
          namespace="cost_of_active_labour"
          :config="{}"
          :start-time-in-millis="null"
          :end-time-in-millis="0"
        />
        <v-btn
          v-if="tab.key === 'not-clocked-out'"
          block
          style="margin-bottom: 20px;"
          color="primary"
          @click="setWorkNotClockedOut"
        >
          Refresh
        </v-btn>
        <WorkList
          v-if="tab.key === 'filtered'"
          :work="filteredWork"
          :step="50"
          @refreshWorkPage="refresh"
          @workCancelled="refresh"
        />
        <WorkList
          v-if="tab.key === 'today'"
          :work="workToday"
          :step="50"
          @refreshWorkPage="refresh"
          @workCancelled="refresh"
        />
        <WorkList
          v-if="tab.key === 'yesterday'"
          :work="workYesterday"
          :step="50"
          @refreshWorkPage="refresh"
          @workCancelled="refresh"
        />
        <WorkList
          v-if="tab.key === 'not-clocked-out'"
          :work="workNotClockedOut"
          :step="50"
          @refreshWorkPage="refresh"
          @workCancelled="refresh"
        />
        <WorkList
          v-if="tab.key === 'all'"
          :work="work"
          :step="50"
          @refreshWorkPage="refresh"
          @workCancelled="refresh"
        />
        <WorkList
          v-if="tab.isLocation"
          :work="getWorkByLocation(tab.key)"
          :step="50"
          @refreshWorkPage="refresh"
          @workCancelled="refresh"
        />
      </v-tab-item>
    </v-tabs-items>
    <WorkDialog
      v-model="showWorkDialog"
      type="add"
      @workHoursUpdated="refresh"
    />
  </v-container>
</template>

<script>

  import WorkList from "@/components/WorkList"
  import WorkDialog from "@/components/WorkDialog"
	import FilterHeader from '@/components/universalFilter/FilterHeader'
  import TransientDash from "@/components/dash/TransientDash"

  export default {
		name: 'Work',
		components: {
      TransientDash,
			WorkList,
			WorkDialog,
			FilterHeader
		},
		data: () => ({
      workNotClockedOut: [],
			showWorkDialog: false,
			actionType: "create",
			work: [],
			filteredWork: [],
			oneDayInMillis: 86400000,
			forceRenderKey: 0,
			startTimeInMillis: 0,
			endTimeInMillis: 0
		}),
		watch: {
			work: function (newWork, oldWork) {
				if (newWork !== null && oldWork === null) {
					this.$store.commit('updateAppBarTabs', this.tabs)
				}
			}
		},
 		computed: {
			tabs() {
				const tabs = [
          {
            title: this.$t('live'),
            key: 'not-clocked-out',
            badge: {
              color: 'red',
              content: this.workNotClockedOutLength,
            }
          },
					{
						title: this.$t('filtered'),
						key: 'filtered',
						badge: {
							color: 'green',
							content: this.workFilteredLength,
						}
					},
					{
						title: this.$t('today'),
						key: 'today',
						badge: {
							color: 'green',
							content: this.workTodayLength
						}
					},
					{
						title: this.$t('yesterday'),
						key: 'yesterday',
						badge: {
							color: 'green',
							content: this.workYesterdayLength,
						}
					},
					{
						title: this.$t('all'),
						key: 'all',
						badge: {
							color: 'green',
							content: this.workLength,
						}
					}
				]

				for (let i in this.locations) {
					const location = this.locations[i]
					tabs.push({
						title: location.label,
						key: location.uuid,
						isLocation: true,
						badge: {
							color: 'green',
							content: 0,
						}
					})
				}

				return tabs
			},
			selectedAppBarTab() {
				return this.$store.state.selectedAppBarTab
			},
			locations() {
				return this.$store.state.locations
			},
			workNotClockedOutLength() {
				return this.workNotClockedOut === null ? 0 : this.workNotClockedOut.length
			},
			workYesterdayLength() {
				return this.workYesterday === null ? 0 : this.workYesterday.length
			},
			workTodayLength() {
				return this.workToday === null ? 0 : this.workToday.length
			},
			workLength() {
				return this.work === null ? 0 : this.work.length
			},
			workFilteredLength() {
				return this.filteredWork === null ? 0 : this.filteredWork.length
			},
			workToday() {
				const today = new Date()
				const startOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate()).getTime()
				const endOfDay = today.setUTCHours(23,59,59,999)

				if (this.work === null) {
					return null
				}

				return this.work.filter(work => {

					if (!work || work.start_date === null || work.start_date === undefined) {
						return false
					}

					return parseInt(work.start_date) >= startOfDay && parseInt(work.start_date) <= endOfDay
				})

			},
			workYesterday() {
				const today = new Date()
				const midnight = new Date(today.getFullYear(), today.getMonth(), today.getDate()).getTime()
				const midnightYesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1).getTime()

				if (this.work === null) {
					return null
				}

				return this.work.filter(work => {

					if (!work || work.start_date === null || work.start_date === undefined) {
						return false
					}

					return parseInt(work.start_date) > midnightYesterday && parseInt(work.start_date) < midnight
				})
			},
		},
		methods: {
			refresh() {
				// Currently, must be done this way when adding work or the icons for total work will not update
        this.$store.dispatch('getWork', {
          startTimeInMillis: 0,
          endTimeInMillis: 0,
          returnWork: true
        }).then((result) => {
            if(Array.isArray(result)) {
              this.filteredWork = result.filter(w => {
                if(w) {
				  if (w.start_date === null || w.start_date === undefined) {
					return false
				  }

                  if(w.start_date >= this.startTimeInMillis && w.end_date <= this.endTimeInMillis) {
                    return true
                  }
                }
                return false
              })
            }
            this.work = this.$store.state.work
            this.$store.commit('updateAppBarTabs', this.tabs)})
				// this.$store.dispatch("getInitialData")
			},
			getWorkByLocation() {
				return []
			},
			exportWork() {
				const exportWork = []
				for (const workKey in this.filteredWork) {
					const work = this.filteredWork[workKey]
					if(typeof work == 'undefined' || work === null || typeof work.start_date == 'undefined' || work.start_date === null || typeof work.end_date == 'undefined' || work.end_date === null) {
						continue
					}
					work.start_date = (new Date(work.start_date)).toString().slice(0, 21)
					work.end_date = (new Date(work.end_date)).toString().slice(0, 21)
					exportWork.push(work)
				}
				this.$store.commit('updateExportObjects', exportWork)
			},
			addWork() {
				this.$store.dispatch('getStaff').then(() => {
					this.showWorkDialog = true
				})
			},
			updateValues(payload) {
        if(payload.startTimeInMillis) {
          this.startTimeInMillis = payload.startTimeInMillis
        }
        if(payload.endTimeInMillis) {
          this.endTimeInMillis = payload.endTimeInMillis
        }

				if(payload.staff === null || payload.staff === undefined || payload.staff === '') {
					this.getWorkByPeriod(payload)
				}
				else {
					this.getWorkByPeriodForStaff(payload)
				}
			},
			getWorkByPeriod(payload) {
				this.$store.dispatch('getWork', {
					startTimeInMillis: payload.startTimeInMillis,
					endTimeInMillis: payload.endTimeInMillis,
					returnWork: true
				}).then((response) => {
					this.filteredWork = response
					this.$store.commit('updateAppBarTabs', this.tabs)
					this.forceRenderKey += 1
				})
			},
			getWorkByPeriodForStaff(payload) {
				this.$store.dispatch('getWorkByStaff', {
					startTimeInMillis: payload.startTimeInMillis,
					endTimeInMillis: payload.endTimeInMillis,
					uuid: payload.staff
				}).then(response => {
					this.filteredWork = response
					this.$store.commit('updateAppBarTabs', this.tabs)
					this.forceRenderKey += 1
				})
			},
      setWorkNotClockedOut() {
        this.$store.dispatch('getActiveWork').then(response => {
          this.workNotClockedOut = response
          this.$store.commit('updateAppBarTabs', this.tabs)
          this.forceRenderKey += 1
        })
      },
		},
		created() {
			this.$store.commit('updateAppBarTabs', this.tabs)
			this.$store.commit('updateSelectedAppBarTab', this.tabs[0].key)
			this.$store.commit('updateAppBarExport', {
				color: 'pink',
				callback: this.exportWork
			})
			this.$store.commit('updateAppBarFab', {
				color: 'pink',
				callback: this.addWork,
				title: this.$t('addWork')
			})
			this.$store.dispatch('getWork', {
				startTimeInMillis: 0,
				endTimeInMillis: 0})
				.then(() => {
					this.work = this.$store.state.work
					this.$store.commit('updateAppBarTabs', this.tabs)})

      this.setWorkNotClockedOut()
		}
	}
</script>
